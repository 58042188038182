import React, { useState, useEffect } from 'react';
import { Container, TextField, Button, Typography, Box, Alert } from '@mui/material';
import { useHistory } from 'react-router-dom';
import zyghthd from '../zyghthd.png';
import { loginUser } from '../server/services/User/login';

const Login = () => {
  const [userData, setUserData] = useState({ email: "", password: "", admin: ""});
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const history = useHistory();

  useEffect(() => {
    if (sessionStorage.getItem('token')) {
      history.push('/home');
    }
  }, [history]);

  const handleLogin = async (event) => {
    event.preventDefault();
    setError('');
    setSuccess('');
    const response = await loginUser(userData);
    if (response.error) {
      setError(response.error)
      return;
    }
    setSuccess('Inicio de sesión exitoso');
    sessionStorage.setItem('token', response.access_token);
    sessionStorage.setItem('user', userData["email"]);
    history.push('/home');
  };

  return (
    <Container maxWidth="sm">
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        minHeight="100vh"
      >
        <img src={zyghthd} alt="Logo" style={{ marginBottom: '5%', width: '40%', height: '50%' }} />
        <Typography variant="h6" component="h1" gutterBottom style={{ color: '#424242' }}>

          LabelManager
        </Typography>
        <Box
          component="form"
          onSubmit={handleLogin}
          sx={{ width: '100%', minHeight: '300px', display: 'flex', flexDirection: 'column' }}
        >
          <TextField
            label="Correo Electrónico"
            variant="outlined"
            fullWidth
            margin="normal"
            value={userData["email"]}
            onChange={(e) => setUserData(data => ({ ...data, email: e.target.value }))}
            required
            sx={{ marginBottom: '8px' }}
          />
          <TextField
            label="Contraseña"
            variant="outlined"
            type="password"
            fullWidth
            margin="normal"
            value={userData["password"]}
            onChange={(e) => setUserData(data => ({ ...data, password: e.target.value }))}
            required
            sx={{ marginBottom: '8px' }}
          />
          {error && <Alert severity="error" sx={{ marginBottom: '8px' }}>{error}</Alert>}
          {success && <Alert severity="success" sx={{ marginBottom: '8px' }}>{success}</Alert>}
          <Button
            type="submit"
            variant="contained"
            fullWidth
            size="large"
            sx={{ marginTop: '16px', backgroundColor: '#0686b5', '&:hover': { backgroundColor: '#055f80' } }}
          >
            Iniciar Sesión
          </Button>

          <Typography variant="caption" sx={{margin:'5%', color:'#bdbdbd'}} display="block" gutterBottom>
          V.1.0.0 - 100624
        </Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default Login;
