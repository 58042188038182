import Formulario from '../components/Formulario';
import { Box, Grid, Typography, Chip, Stack } from '@mui/material';
import { useLocation, useParams } from 'react-router-dom';

const Manager = () => {
  const location = useLocation();
  const { logoUrl, customerName, productionStatus } = location.state || {};
  const { customerID } = useParams();


  const getStatusColor = (status) => {
    return status === 'Cliente en Producción' ? 'warning' : 'success';
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 30 }}>
      <Grid container spacing={2} sx={{ marginTop: 5 }} alignItems="center" justifyContent="center">
        <Grid item xs={12} sm={5} sx={{ border: 1, borderColor: 'white' }}>
          <Typography variant="h5" align="left">Administrador de Etiquetas</Typography>
          <Stack direction="row" spacing={2} sx={{ marginTop: 1 }}>
            <Chip label={`Cliente: ${customerName}`} color="primary" variant='outlined' />
            <Chip label={`Estado: ${productionStatus}`} color={getStatusColor(productionStatus)} variant='outlined' />
          </Stack>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Box display="flex" justifyContent="center" sx={{ border: 1, borderColor: 'white' }}>
            {logoUrl && <img src={logoUrl} alt="Logo" style={{ maxWidth: '150px', maxHeight: '60px', objectFit: 'contain' }} />}
          </Box>
        </Grid>
      </Grid>

      <div style={{ marginTop: 5 }}>
        <Formulario customerId={customerID} />
      </div>
    </div>
  );
};

export default Manager;
