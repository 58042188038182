import axios from '../../../utils/axiosConfig';
import apiUrl from "../../config_url";

export async function changeStatus(id) {
    try {
        const response = await axios.post(`${apiUrl}/user/usersid/${id}/status`);
        if (response.status === 200) return response.data;
        return { error: response.data.error };
    } catch (error) {
        console.error(error.message);
        return { error: error.response?.data?.error || 'Error al cambiar el estado del usuario' };
    }
}
