import axios from '../../../utils/axiosConfig';
import apiUrl from "../../config_url";

export const massiveModify = async (data, token) => {
    try {
        const response = await axios.post(
            `${apiUrl}/sql_server/massive-modify`,
            data,
            { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
    } catch (error) {
        console.error('Error modifying labels:', error);
        throw error;
    }
};
