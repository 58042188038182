// server/services/User/changePassword.js

import axios from 'axios';
import apiUrl from '../../config_url';

export async function changePassword({ user_id, old_password, new_password, confirm_password, token }) {
    try {
        const response = await axios.post(`${apiUrl}/user/change_password`, {
            user_id,
            old_password,
            new_password,
            confirm_password
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error(error.message);
        return { error: error.response?.data?.error || 'Error al cambiar la contraseña' };
    }
}
