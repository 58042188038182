import apiUrl from "../../config_url"
import axios from '../../../utils/axiosConfig';


export default async function getAllCustomers(){
    try {
        const response = await axios.get(`${apiUrl}/customer/get_all`)
        if(response.status === 200) return response.data
        return {error: response.data.error || "Ha habido un error"}
    } catch (error) {
        console.error(error);
        return {error:error?.response?.data?.error || "Ha habido un error al cargar los clientes"}
    }
}