import axios from '../../../utils/axiosConfig';
import apiUrl from "../../config_url";

export const unitModify = async (params, token) => {
    try {
        const response = await axios.post(
            `${apiUrl}/sql_server/modify`,
            params,
            { headers: { Authorization: `Bearer ${token}` } }
        );
        return response.data;
    } catch (error) {
        console.error('Error modifying label:', error);
        throw error;
    }
};
