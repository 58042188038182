import axios from '../../../utils/axiosConfig';
import apiUrl from "../../config_url"

export async function registerUser({email, password,admin}){
    try {
        const response = await axios.post(`${apiUrl}/user/register`, {email, password,admin})
        if(response.status === 201) return response.data
        return {error:response.data.error}            
    } catch (error) {
        console.error(error.message);
        return {error: error.response?.data?.error || 'Error al iniciar sesión'}
    }
}