

import React from 'react';
import { BrowserRouter as Router, Route, Switch, Redirect, useLocation } from 'react-router-dom';
import TopBar from './components/TopBar';
import './App.css';
import Home from './pages/Home';
import Login from './pages/Login';
import Manager from './pages/Manager';
import PrivateRoute from './components/PrivateRoute';
import Settings from './pages/Settings';


const App = () => {
  return (
    <Router>
      <div className="App">
        <ConditionalTopBar />
        <Switch>
          <Route exact path="/" component={Login} />
          <PrivateRoute exact path="/home" component={Home} />
          <PrivateRoute exact path="/manager/:customerID" component={Manager} />
          <PrivateRoute exact path="/settings" component={Settings}adminOnly />
          <Redirect to="/" />
        </Switch>
      </div>
    </Router>
  );
};

const ConditionalTopBar = () => {
  const location = useLocation();
  const pathsWithTopBar = ['/home', '/manager', '/manager/:customerID', '/settings'];

  const shouldShowTopBar = (pathname) => {
    return pathsWithTopBar.some(path => {
      const regex = new RegExp(`^${path.replace(/:\w+/g, '\\w+')}$`);
      return regex.test(pathname);
    });
  };

  return shouldShowTopBar(location.pathname) ? <TopBar /> : null;
};

export default App;


