// 

// import React from 'react';
// import { Route, Redirect } from 'react-router-dom';

// const PrivateRoute = ({ component: Component, adminOnly, ...rest }) => {
//   const isAuthenticated = sessionStorage.getItem('token');
//   const isAdmin = sessionStorage.getItem('admin') === 'rue'; // Verifica si el usuario es admin

//   return (
//     <Route
//       {...rest}
//       render={(props) =>
//         isAuthenticated ? (
//           adminOnly && !isAdmin ? (
//             <Redirect to="/home" /> // Redirige a home si no es admin y la ruta es solo para admins
//           ) : (
//             <Component {...props} />
//           )
//         ) : (
//           <Redirect to="/" /> // Redirige al login si no está autenticado
//         )
//       }
//     />
//   );
// };

// export default PrivateRoute;


import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';

const PrivateRoute = ({ component: Component, adminOnly, ...rest }) => {
  const token = sessionStorage.getItem('token');
  let isAuthenticated = false;
  let isAdmin = false;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      isAuthenticated = true;
      isAdmin = decodedToken.sub.admin === true; // Verifica si el usuario es admin
    } catch (e) {
      console.error('Invalid token', e);
    }
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!isAuthenticated) {
          return <Redirect to="/" />; // Redirige al login si no está autenticado
        }

        if (adminOnly && !isAdmin) {
          return <Redirect to="/home" />; // Redirige a home si no es admin y la ruta es solo para admins
        }

        return <Component {...props} />; // Renderiza el componente si todo está bien
      }}
    />
  );
};

export default PrivateRoute;

