import axios from 'axios';
import apiUrl from "../../config_url"

export async function loginUser({email, password}){
    try {
        const response = await axios.post(`${apiUrl}/user/login`, {email, password})
        if(response.status === 200) return response.data
        return {error:response.data.error}            
    } catch (error) {
        console.error(error.message);
        return {error: error.response?.data?.error || 'Error al iniciar sesión'}
    }
}