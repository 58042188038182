import axios from 'axios';

axios.interceptors.request.use(
    config => {
        const token = sessionStorage.getItem('token');  
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(
    response => response,
    error => {
        if (error.response && error.response.status === 401) {
            sessionStorage.removeItem('token');
            alert('Su sesión ha expirado. Por favor, inicie sesión nuevamente.');
            window.location.href = '/';
        }
        return Promise.reject(error);
    }
);

export default axios;
