import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import HomeIcon from '@mui/icons-material/Home';
import SettingsIcon from '@mui/icons-material/Settings';
import PowerSettingsNewOutlinedIcon from '@mui/icons-material/PowerSettingsNewOutlined';
import Box from '@mui/material/Box';
import zyghthd from '../zyghthd.png';
import { useHistory } from 'react-router-dom';
import {jwtDecode} from 'jwt-decode';

const TopBar = () => {
  const history = useHistory();
  const user = sessionStorage.getItem('user');
  const token = sessionStorage.getItem('token');

  let isAdmin = false;

  if (token) {
    try {
      const decodedToken = jwtDecode(token);
      isAdmin = decodedToken.sub.admin === true;
    } catch (e) {
      console.error('Invalid token', e);
    }
  }

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    history.push('/');
  };

  const handleNavigation = (path) => {
    history.push(path);
    handleMenuClose();
  };

  return (
    <AppBar position="static" sx={{ width: '100%', backgroundColor: '#eeeeee' }}>
      <Toolbar>
        <Box sx={{ display: 'flex', alignItems: 'center', flexGrow: 1 }}>
          <a href='/'>
            <img src={zyghthd} alt="Logo" style={{ height: 40, marginRight: 16 }} />
          </a>
        </Box>
        {user && (
          <Typography variant="body2" sx={{ color: '#000000', marginRight: 2 }}>
            {user}
          </Typography>
        )}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          sx={{ color: '#616161'}}
          onClick={handleMenuOpen}
        >
          <MenuIcon />
        </IconButton>
        <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleNavigation('/home')} key="home">
              <HomeIcon sx={{ marginRight: 1 }} />
              Home
            </MenuItem>
            {isAdmin && (
              <MenuItem onClick={() => handleNavigation('/settings')} key="settings">
                <SettingsIcon sx={{ marginRight: 1 }} />
                Settings
              </MenuItem>
            )}
            <MenuItem onClick={handleLogout} key="logout">
              <PowerSettingsNewOutlinedIcon sx={{ marginRight: 1 }} />
              Cerrar sesión
            </MenuItem>
          </Menu>

      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
