import React, { useState } from 'react';
import { Box, Alert } from '@mui/material';
import Instancias from '../components/Instancias';

const Home = () => {
  const [open, setOpen] = useState(true); 

  const handleClose = () => {
    setOpen(false); 
  };

  return (
    <Box mb={4}>
      {open && ( 
        <Alert severity="info"  onClose={handleClose} sx={{margin:5 }}>
        Recuerda que las instancias están operativas para nuestros clientes. <b>Cualquier cambio que realices afectará al servicio del cliente en tiempo real. </b>
        </Alert>

      )}
      <div style={{ display: 'flex', flexDirection: 'column', margin:40}}>
      <Instancias />
      </div>


    </Box>
    
  );
};

export default Home;
