import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Snackbar, Typography, IconButton, Checkbox, Switch, LinearProgress } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { esES } from '@mui/x-data-grid/locales';
import FormularioBusqueda from './FormularioBusqueda';
import getDataPool from '../server/services/SqlServer/getUserPool';
import { useParams } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import CheckIcon from '@mui/icons-material/Check';
import { unitModify } from '../server/services/SqlServer/unitModify';
import { massiveModify } from '../server/services/SqlServer/massiveModify';
import Alert from '@mui/material/Alert';


const theme = createTheme(
  {
    palette: {
      primary: { main: '#1976d2' },
    },
  },
  esES
);

const CustomDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader, & .MuiDataGrid-root .MuiDataGrid-cell': {
    fontSize: '0.875rem',
  },
  '& .MuiDataGrid-root .MuiDataGrid-columnHeader': {
    fontWeight: 'bold',
    backgroundColor: '#4fc3f7',
  },
}));

const Formulario = ({ customerId }) => {

  const [searchResults, setSearchResults] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [currentLabel, setCurrentLabel] = useState('');
  const [newLabel, setNewLabel] = useState('');
  const [labelKey, setLabelKey] = useState('');
  const [LanguageId, setLanguageId] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [isSaveDisabled, setIsSaveDisabled] = useState(true);
  const [shouldSearchAgain, setShouldSearchAgain] = useState(false);
  const [isMassModification, setIsMassModification] = useState(false);
  const [showMassActionButton, setShowMassActionButton] = useState(false);
  const [openModalbulk, setOpenModalbulk] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [old_word, setOld_word] = useState('');
  const [new_word, setNew_word] = useState('');
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [progress, setProgress] = useState(0);
  const [isSaving, setIsSaving] = useState(false);
  const [allSelected, setAllSelected] = useState(false);
  const { customerID } = useParams();


  const token = sessionStorage.getItem('token'); 


  const fetchData = async () => {
    try {
      const result = await getDataPool(customerId);
      setSearchResults(result.labels.map((item, index) => ({
        ...item,
        id: item.id,
        action: isMassModification ? <Checkbox color="primary" checked={selectedIds.includes(item.id)} onChange={(e) => handleCheckboxChange(e, item)} /> : <IconButton variant="contained" label="Editar" color="primary" size="small" onClick={() => handleModify(item.Label, item.LabelKey, item.LanguageId)}>
          <EditIcon />
        </IconButton>
      })));
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };



  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (openModal) {
      setNewLabel('');
    }
  }, [openModal]);



  const handleSearchLabel = async () => {
    const result = await getDataPool(customerId);
    setSearchResults(result.map(item => ({
      ...item,
      id: item.id,
      action: isMassModification ? <Checkbox color="primary" /> : <IconButton variant="contained" label="Editar" color="primary" size="small" onClick={() => handleModify(item.Label, item.LabelKey)}>
        <EditIcon />
      </IconButton>
    })));
  };

  
  const handleModify = (label, labelKey, LanguageId) => {
    setCurrentLabel(label);
    setLabelKey(labelKey);
    setLanguageId(LanguageId);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setNewLabel('');
    if (shouldSearchAgain) {
      handleSearchLabel();
      setShouldSearchAgain(false);
    }
  };

  const handleCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedIds(prevIds => [...prevIds, id]);
    } else {
      setSelectedIds(prevIds => prevIds.filter(item => item !== id));
    }
    setAllSelected(selectedIds.length + 1 === searchResults.length); 
  };

  const handleMassModification = (e) => {
    const isChecked = e.target.checked;
    setIsMassModification(isChecked);
    setShowMassActionButton(isChecked);
    if (!isChecked) {
      setSelectedIds([]);
      setSelectedLabels([]);
    }
  };

  const handleSaveMassEdit = () => {
    if (selectedIds.length === 0) {
      alert('Por favor, seleccione al menos una etiqueta para modificar.');
      return;
    }
    setOpenModalbulk(true);
  };

  const handleCloseModalbulk = () => {
    setOpenModalbulk(false);
    setOld_word('');
    setNew_word('');
    if (shouldSearchAgain) {
      handleSearchLabel();
      setShouldSearchAgain(false);
    }
  };

  const handleLabelSelect = (labelData) => {
    setSelectedLabels(prevLabels => {
      const isAlreadySelected = prevLabels.some(label => label.id === labelData.id);

      if (isAlreadySelected) {
        return prevLabels.filter(label => label.id !== labelData.id);
      } else {
        return [...prevLabels, labelData];
      }
    });
  };

  const handleSelectAll = () => {
    setAllSelected(!allSelected);
    if (!allSelected) {
      setSelectedIds(searchResults.map(item => item.Id));
      setSelectedLabels(searchResults.map(item => ({
        id: item.Id,
        labelKey: item.LabelKey,
        label: item.Label,
        languageId: item.LanguageId
      })));
    } else {
      setSelectedIds([]);
      setSelectedLabels([]);
    }
  };

 
  
  const handleMassSave = async () => {
    if (selectedLabels.length === 0) {
        alert('Por favor, seleccione al menos una etiqueta para modificar.');
        return;
    }

    const dataToSend = {
        user_id: customerID,
        labels: selectedLabels.map(label => ({
            label_key: label.labelKey,
            full_label: label.label,
            old_word: old_word,
            new_word: new_word,
            language_id: label.languageId
        }))
    };

    setProgress(0);
    setIsSaving(true);

    const updateProgress = () => {
        setProgress(prev => {
            if (prev >= 100) {
                clearInterval(interval);
                return 100;
            }
            return prev + 10;
        });
    };
    const interval = setInterval(updateProgress, 500);

    try {
        const token = sessionStorage.getItem('token');
        const response = await massiveModify(dataToSend, token);

        setSnackbarMessage('Etiquetas modificadas correctamente');
        setSnackbarOpen(true);
        setSelectedLabels([]);
        setOld_word('');
        setNew_word('');
        setShouldSearchAgain(true);
        setNewLabel('');
        setAllSelected(false);
        setSelectedIds([]);
        clearInterval(interval);
        setProgress(100);
        fetchData();
    } catch (error) {
        console.error('Error al modificar etiquetas:', error);
        clearInterval(interval);
        setProgress(0);
    } finally {
        setIsSaving(false);
        setTimeout(() => {
            setProgress(0);
            setOpenModalbulk(false);
        }, 1000);
    }
};



  const handleSave = async () => {
    const params = {
        user_id: customerID,
        labelKey: labelKey,
        newLabel: newLabel,
        language_id: LanguageId,
        old_label: currentLabel
    };

    try {
        const response = await unitModify(params, token);

        console.log('Etiqueta modificada correctamente:', response);
        setSnackbarMessage('Etiqueta modificada correctamente');
        setSnackbarOpen(true);

        setSearchResults(searchResults.map(item =>
            (item.LabelKey === labelKey && item.LanguageId === LanguageId) ? { ...item, Label: newLabel } : item
        ));

        setTimeout(() => {
            setOpenModal(false);
            setNewLabel('');
        }, 2000);
    } catch (error) {
        console.error('Error al modificar etiqueta:', error);
        alert('Error al modificar la etiqueta. Por favor, inténtelo de nuevo.');
    }
};

  

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  const handleNewLabelChange = (e) => {
    setNewLabel(e.target.value);
    setIsSaveDisabled(e.target.value === '');
  };

  const getLanguageName = (languageId) => {
    switch (languageId) {
      case 1:
        return 'Español';
      case 2:
        return 'Inglés';
      case 3:
        return 'Portugués';
      default:
        return 'Desconocido';
    }
  };

  const columns = [
    { field: 'Id', headerName: 'ID', width: 100 },
    { field: 'LabelKey', headerName: 'LabelKey', width: 300 },
    { field: 'Label', headerName: 'Etiqueta', width: 500 },
    { field: 'Module', headerName: 'Módulo', width: 150 },
    { field: 'Tipo', headerName: 'Origen', width: 120},
    { field: 'LanguageId', headerName: 'Idioma', width: 150, renderCell: (params) => getLanguageName(params.value) },
    {
      field: 'action', headerName: 'Acciones', width: 100, sortable: false,
      renderCell: (params) => isMassModification ?
        <Checkbox
          color="primary"
          checked={selectedIds.includes(params.row.Id)}
          onChange={(e) => {
            handleCheckboxChange(e, params.row.Id);
            handleLabelSelect({ id: params.row.Id, labelKey: params.row.LabelKey, label: params.row.Label, languageId: params.row.LanguageId });
          }}
        /> :
        <IconButton
          variant="contained"
          color="primary"
          size="small"
          onClick={() => handleModify(params.row.Label, params.row.LabelKey, params.row.LanguageId)}
        >
          <EditIcon />
        </IconButton>
    }
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ marginTop: 2 }}>
          <FormularioBusqueda onSearchResults={setSearchResults} />
        </Box>

        <Grid container sx={{ display: 'flex', marginTop: 2, marginLeft: 1, justifyContent: 'space-between' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
            <Typography variant="body1">Edición Unitaria</Typography>
            <Switch
              checked={isMassModification}
              onChange={handleMassModification}
              color="primary"
              inputProps={{ 'aria-label': 'toggle mass modification switch' }}
              sx={{ marginLeft: 1 }}
            />
            <Typography variant="body1">Edición Masiva</Typography>
          </Box>
          {showMassActionButton && (
            <Box sx={{ display: 'flex', alignItems: 'center', margin: 2 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handleSaveMassEdit}
                style={{ textTransform: 'none' }}
                startIcon={<EditIcon />}
              >
                Modificar Selección
              </Button>
              <Button
                variant="outlined"
                color="success"
                onClick={handleSelectAll}
                startIcon={<CheckIcon />}
                
                style={{ textTransform: 'none', marginLeft: '10px' }}
              >
                {allSelected ? 'Deseleccionar Todo' : 'Seleccionar Todo'}
              </Button>
            </Box>
          )}
        </Grid>
        <div style={{ height: 'auto', width: '100%', marginTop: 20 }}>
          <CustomDataGrid
            initialState={{
              columns: {
                columnVisibilityModel: {
                  Id: false,
                  status: false,
                  traderName: false,
                },
              },
            }}
            rows={searchResults}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            autoHeight
            getRowId={(row) => row.Id}
          />
        </div>
        <Dialog
          open={openModal}
          onClose={handleCloseModal}
          fullWidth
          maxWidth="md"
        >
          
          <DialogTitle>Modificar Etiqueta</DialogTitle>
          <Alert severity="warning" sx={{ margin: 1 }}>
              La modificacion de etiquetas se aplicará a la instancia en producción. Recuerda que los cambios se verán reflejados en la plataforma.
          </Alert>
          <DialogContent>
            <TextField
              label="LabelKey"
              variant="outlined"
              size="medium"
              sx={{ marginTop: 2, width: '50%' }}
              value={labelKey}
              disabled
            />
            <TextField
              label="Idioma"
              variant="outlined"
              size="medium"
              value={LanguageId === 1 ? 'Español' : LanguageId === 2 ? 'Inglés' : LanguageId === 3 ? 'Portugués' : 'Desconocido'}
              disabled
              sx={{ marginTop: 2, marginLeft: 2, width: '48%' }}
            />
            <TextField
              label="Etiqueta Actual"
              variant="outlined"
              size="medium"
              fullWidth
              sx={{ marginTop: 2, marginBottom: 2 }}
              value={currentLabel}
              disabled
            />
            <TextField
              label="Nueva Etiqueta"
              variant="outlined"
              fullWidth
              focused
              color='primary'
              value={newLabel}
              onChange={handleNewLabelChange}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseModal} variant="outlined" color="error">
              Cancelar
            </Button>
            <Button onClick={handleSave} variant="outlined" color="success" disabled={isSaveDisabled}>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openModalbulk}
          onClose={handleCloseModalbulk}
          fullWidth
          maxWidth="md"
        >
          <DialogTitle>Reemplazar de manera masiva</DialogTitle>
          <DialogContent>
            {!isSaving ? (
              <>
                <Alert severity="warning" sx={{ marginBottom: 2 }}>
                  La modificacion masiva reemplazará la palabra indicada en todas las etiquetas seleccionadas.
                </Alert>
                <TextField
                  label="Palabra a reemplazar"
                  helperText="Digite la palabra que desea reemplazar"
                  variant="outlined"
                  fullWidth
                  sx={{ margin: 2, width: '70%' }}
                  value={old_word}
                  onChange={(e) => setOld_word(e.target.value)}
                />
                <TextField
                  label="Nueva Palabra"
                  helperText="Digite la nueva palabra"
                  sx={{ margin: 2, width: '70%' }}
                  variant="outlined"
                  fullWidth
                  value={new_word}
                  onChange={(e) => setNew_word(e.target.value)}
                />
              </>
            ) : (
              <Box sx={{ width: '100%', marginTop: 2 }}>
                <LinearProgress variant="determinate" value={progress} />
                <Typography variant="body2" color="textSecondary" align="center">{`${Math.round(progress)}%`}</Typography>
              </Box>
            )}
          </DialogContent>
          <DialogActions style={{ justifyContent: 'center' }}>
            <Button onClick={handleCloseModalbulk} variant="outlined" color="error">
              Cancelar
            </Button>
            <Button onClick={handleMassSave} variant="outlined" color="success" disabled={isSaving}>
              Guardar
            </Button>
          </DialogActions>
        </Dialog>
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={2000}
          onClose={handleSnackbarClose}
          message={snackbarMessage}
        />
      </Box>
    </ThemeProvider>
  );
};

export default Formulario;
