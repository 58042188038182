
import apiUrl from "../../config_url";
import axios from '../../../utils/axiosConfig';




const clientFunctions = {
  getLogo: (userId) => axios.get(`${apiUrl}/sql_server/logo`, { params: { user_id: userId } }),
  getCustomerName: (userId) => axios.get(`${apiUrl}/customer/customer_name`, { params: { user_id: userId } }),
  getProductionStatus: (userId) => axios.get(`${apiUrl}/customer/production_status`, { params: { user_id: userId } })
};

export default clientFunctions;
