import axios from '../../../utils/axiosConfig';
import apiUrl from "../../config_url";

export const fullSearch = async (params) => {
    try {
        const response = await axios.get(`${apiUrl}/sql_server/search`, { params });
        return response.data;
    } catch (error) {
        console.error('Error fetching users:', error);
        throw error;
    }
};
