import React, { useState } from 'react';
import { TextField, Button, Select, MenuItem } from '@mui/material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { fullSearch } from '../server/services/SqlServer/search';


const FormularioBusqueda = ({ onSearchResults }) => {
    const [searchTermLabelKey, setSearchTermLabelKey] = useState('');
    const [searchTermLabel, setSearchTermLabel] = useState('');
    const [searchTermModule, setSearchTermModule] = useState('');
    const [languageId, setLanguageId] = useState(4); 
    const {customerID} = useParams()

    
    const handleSearch = async () => {
        const params = {
            user_id: customerID || "",  
            labelKey: searchTermLabelKey || "",
            label: searchTermLabel || "",
            module: searchTermModule || "",
            languageId: languageId || "1"  
        };
    
        try {
            const response = await fullSearch(params);
            onSearchResults(response.labels);  
        } catch (error) {
            console.error('Error al realizar la búsqueda:', error);
            onSearchResults([]);  
        }
    };
    
    return (
        <div style={{ marginTop: 50 }}>
            <TextField
                label="Etiqueta"
                value={searchTermLabel}
                onChange={e => setSearchTermLabel(e.target.value)}
                sx={{ marginRight: 2 }}
                size='small'
                focused


            />
            <TextField
                label="Label Key"
                value={searchTermLabelKey}
                onChange={e => setSearchTermLabelKey(e.target.value)}
                sx={{ marginRight: 2 }}
                size='small'

            />
            <TextField
                label="Modulo"
                value={searchTermModule}
                onChange={e => setSearchTermModule(e.target.value)}
                sx={{ marginRight: 2 }}
                size='small'


            />
            
            <Select
                value={languageId}
                onChange={e => setLanguageId(e.target.value)}
                displayEmpty
                sx={{ marginRight: 1, width: 200}}
                size='small'
                helperText="Seleccione un idioma"

                

            >
                <MenuItem value={1}>Español</MenuItem>
                <MenuItem value={2}>Inglés</MenuItem>
                <MenuItem value={3}>Portugués</MenuItem>
                <MenuItem value={4}>Todos los idiomas</MenuItem>
            </Select>
            <Button variant="contained" 
            size='large'
            onClick={handleSearch}
            style={{textTransform: 'none'}}
            startIcon={<SearchOutlinedIcon />}
            >Buscar</Button>

        </div>
    );
};

export default FormularioBusqueda;