import React, { useState, useEffect } from 'react';
import InfoIcon from '@mui/icons-material/Info';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, Typography, Button, Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useHistory } from 'react-router-dom';
import getAllCustomers from '../server/services/Customer/getAllCustomers';
import connectPool from '../server/services/SqlServer/connectPool';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import clientFunctions from '../server/services/SqlServer/clientFunctions';
import ContinueIcon from '@mui/icons-material/ArrowForward';
import CancelIcon from '@mui/icons-material/Cancel';

const Instancias = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');
  const [loadingButton, setLoadingButton] = useState(null);
  const [customerId, setCustomerId] = useState(0);
  const [dialogLoading, setDialogLoading] = useState(false); 
  const history = useHistory();

  useEffect(() => {
    const fetchData = async () => {
      const customers = await getAllCustomers();
      if(customers.error){
        setError(customers.error);
        return;
      }
      if (!Array.isArray(customers)){
        setError("Los datos recibidos no son un array");
        return;
      }
      setRows(customers);
      setLoading(false);
    };

    fetchData();
  }, []);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleIngresarClick = async (customerSetupId, sqlserverid, database, customerName) => {
    setLoadingButton(customerSetupId);
    setCustomerId(customerSetupId);
    setDialogLoading(true);

    const dataToSend = {
      sql_server_id: sqlserverid,
      database,
      user_id: customerSetupId
    }
    setOpenDialog(true);
    const sqlDetails = await connectPool(dataToSend);
    if(sqlDetails.error){
      setDialogMessage("Error al establecer la conexión");
      setDialogLoading(false); 
      setLoadingButton(null);
      return;
    }

    setDialogMessage(`${sqlDetails.msg} . Te conectarás a la instancia cliente ${customerName}`);
    setLoadingButton(false);
    setDialogLoading(false); 
  };

  const handleContinueClick = async () => {
    setDialogLoading(true); 

    try {
      const logoResponse = await clientFunctions.getLogo(customerId);
      const logoUrl = logoResponse.data.logo;

      const nameResponse = await clientFunctions.getCustomerName(customerId);
      const customerName = nameResponse.data.customer_name;

      const statusResponse = await clientFunctions.getProductionStatus(customerId);
      const productionStatus = statusResponse.data.production_status ? 'Cliente en Producción' : 'Versión Demo';

      history.push({
        pathname: `/manager/${customerId}`,
        state: {
          logoUrl,
          customerName,
          productionStatus,
        }
      });
    } catch (error) {
      console.error("Error al cargar elementos:", error);
    } finally {
      setDialogLoading(false); 
      setOpenDialog(false); 
    }
  };

  const filteredRows = Array.isArray(rows) ? rows.filter((row) => {
    const customerName = row.CustomerName ? row.CustomerName.toString().toLowerCase() : '';
    const database = row.database ? row.database.toString().toLowerCase() : '';
    const sqlserverid = row.sqlserverid ? row.sqlserverid.toString().toLowerCase() : '';
    const searchTermLower = searchTerm.toLowerCase();

    return (
      customerName.includes(searchTermLower) ||
      database.includes(searchTermLower) ||
      sqlserverid.includes(searchTermLower)
    );
  }) : [];

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">{error}</Typography>;
  }

  return (
    <div>
      <Box display="flex" mb={4}>
        <TextField 
          label="Buscar Cliente" 
          variant="outlined" 
          sx={{ width: '50%' }} 
          size='small'
          margin="normal" 
          value={searchTerm}
          onChange={handleSearchChange}
        />
      </Box>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID del Cliente</TableCell>
              <TableCell>Nombre del Cliente</TableCell>
              <TableCell>Base de Datos</TableCell>
              <TableCell>Estado</TableCell>
              <TableCell>Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRows.map((row) => (
              <TableRow key={row.CustomerSetupId}>
                <TableCell>{row.CustomerSetupId}</TableCell>
                <TableCell>{row.CustomerName}</TableCell>
                <TableCell>{row.database}</TableCell>
                <TableCell>{row.Production ? 'Producción':'Demo'}</TableCell>
                <TableCell>
                  <Button 
                    variant="contained" 
                    size="small" 
                    color="success"
                    style={{textTransform: 'none'}}
                    endIcon={<ArrowOutwardOutlinedIcon />}
                    sx={{ backgroundColor: '#0686b5', '&:hover': { backgroundColor: '#1EC2DE' } }}
                    onClick={() => handleIngresarClick(row.CustomerSetupId, row.sqlserverid, row.database, row.CustomerName)}
                    disabled={loadingButton === row.CustomerSetupId}
                  >
                    {loadingButton === row.CustomerSetupId ? <CircularProgress size={24} sx={{ color: '#055f80' }} /> : 'Ingresar'}
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Box display="flex" alignItems="center">
            <InfoIcon sx={{ verticalAlign: 'middle', marginRight: 1, color: '#ffc107' }} />
            Confirmación de Acceso
          </Box>
        </DialogTitle>
        <DialogContent sx={{ textAlign: 'center', minHeight: '150px' }}>
          <Typography variant="body1">
            Ingresarás a una instancia en producción, ¿Estás seguro de continuar?
          </Typography>
          <DialogContentText sx={{ fontWeight: 'bold', textAlign: 'center', marginTop: 2 }}>
            {dialogLoading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress size={50} sx={{ color: '#1EC2DE' }} />
              </Box>
            ) : (
              <div>{dialogMessage}</div> 
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center' }}>
          {!dialogLoading && (
            <Button
              onClick={handleContinueClick}
              color="primary"
              variant="outlined"
              size="small"
              endIcon={<ContinueIcon />}
              sx={{ textTransform: 'none' }}
            >
              Continuar
            </Button>
          )}
          <Button
            onClick={() => setOpenDialog(false)}
            color="error"
            variant="outlined"
            size="small"
            endIcon={<CancelIcon />}
            sx={{ textTransform: 'none' }}
          >
            Volver
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Instancias;
