import React, { useEffect, useState } from 'react';
import {
    Container, Alert, Button, Box, TextField, FormControl, InputLabel, Select, MenuItem,
    Grid, Typography, createTheme, ThemeProvider, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle, IconButton, InputAdornment
} from '@mui/material';
import { Visibility, VisibilityOff, Refresh } from '@mui/icons-material';
import zxcvbn from 'zxcvbn';
import { DataGrid } from '@mui/x-data-grid';
import { userRegistered } from '../server/services/User/userRegister';
import { registerUser } from '../server/services/User/register';
import { changeStatus } from '../server/services/User/changeStatus';
import { changePassword } from '../server/services/User/changePassword';

const theme = createTheme({
    palette: {
        primary: {
            main: '#1976d2',
        },
        secondary: {
            main: '#dc004e',
        },
    },
    typography: {
        h5: {
            fontWeight: 600,
        },
    },
});

const UserRegister = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [passwordStrength, setPasswordStrength] = useState(0);
    const [passwordStrengthChange, setPasswordStrengthChange] = useState(0);
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [userData, setUserData] = useState({
        email: '',
        password: '',
        confirmPassword: '',
        admin: 0,
    });
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [passwordData, setPasswordData] = useState({
        user_id: null,
        old_password: '',
        new_password: '',
        confirm_password: ''
    });

    const fetchUsers = async () => {
        try {
            const response = await userRegistered();
            if (response.error) {
                setError(response.error);
                setLoading(false);
                return;
            }
            setUsers(response);
            setLoading(false);
        } catch (error) {
            console.error('Error fetching users', error);
            setError('Error al obtener los usuarios');
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchUsers();
    }, []);

    useEffect(() => {
        if (error || success) {
            const timer = setTimeout(() => {
                setError('');
                setSuccess('');
            }, 3000);
            return () => clearTimeout(timer);
        }
    }, [error, success]);

    const handleRegister = async (e) => {
        e.preventDefault();
        setError('');
        setSuccess('');

        if (userData.password !== userData.confirmPassword) {
            setError('Las contraseñas no coinciden');
            return;
        }

        const registerData = { email: userData.email, password: userData.password, admin: userData.admin };

        const response = await registerUser(registerData);

        if (response.error) {
            setError(response.error);
            return;
        }
        setSuccess('Registro exitoso');
        await fetchUsers();
    };

    const handleChangeStatus = async (id) => {
        const response = await changeStatus(id);
        if (response.error) {
            setError(response.error);
        } else {
            setSuccess(response.msg);
            setUsers(users.map(user => user.id === id ? { ...user, status: !user.status } : user));
        }
    };

    const handleOpenChangePassword = (userId) => {
        setPasswordData({ ...passwordData, user_id: userId });
        setOpenChangePassword(true);
    };

    const handleCloseChangePassword = () => {
        setOpenChangePassword(false);
        setPasswordStrengthChange(0); // Reiniciar la fuerza de la contraseña
        setPasswordData({
            user_id: null,
            old_password: '',
            new_password: '',
            confirm_password: ''
        });
    };

    const handleChangePassword = async () => {
        const token = sessionStorage.getItem('token');
        const response = await changePassword({ ...passwordData, token });
        if (response.error) {
            setError(response.error);
        } else {
            setSuccess(response.msg);
            handleCloseChangePassword();
        }
    };

    const handlePasswordChange = (e) => {
        const { value } = e.target;
        setUserData(data => ({ ...data, password: value }));
        setPasswordStrength(zxcvbn(value).score);
    };

    const handleNewPasswordChange = (e) => {
        const { value } = e.target;
        setPasswordData(data => ({ ...data, new_password: value }));
        setPasswordStrengthChange(zxcvbn(value).score);
    };

    const generatePassword = () => {
        const length = 20;
        const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#&*_";
        let password = "";
        for (let i = 0, n = charset.length; i < length; ++i) {
            password += charset.charAt(Math.floor(Math.random() * n));
        }
        return password;
    };

    const handleGeneratePassword = () => {
        const password = generatePassword();
        setUserData(data => ({ ...data, password, confirmPassword: password }));
        setPasswordStrength(zxcvbn(password).score);
    };

    const handleGenerateNewPassword = () => {
        const password = generatePassword();
        setPasswordData(data => ({ ...data, new_password: password, confirm_password: password }));
        setPasswordStrengthChange(zxcvbn(password).score);
    };

    const getPasswordStrengthColor = (score) => {
        switch (score) {
            case 0: return 'grey';
            case 1: return 'red';
            case 2: return 'orange';
            case 3: return 'green';
            case 4: return 'green';
            default: return 'grey';
        }
    };

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        { field: 'username', headerName: 'Username', width: 300 },
        {
            field: 'admin',
            headerName: 'Nivel',
            width: 200,
            renderCell: (params) => (
                <span>
                    {params.value ? 'Administrador' : 'Usuario'}
                </span>
            )
        },
        {
            field: 'status',
            headerName: 'Estado',
            width: 200,
            renderCell: (params) => (
                <span>
                    {params.value ? 'Activo' : 'Inactivo'}
                </span>
            )
        },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 300,
            renderCell: (params) => (
                <Box>
                    <Button
                        variant="outlined"
                        color="primary"
                        size="small"
                        onClick={() => handleChangeStatus(params.row.id)}
                        sx={{ marginLeft: 1, textTransform: 'none' }}
                    >
                        Cambiar Estado
                    </Button>
                    <Button
                        variant="outlined"
                        color="secondary"
                        size="small"
                        onClick={() => handleOpenChangePassword(params.row.id)}
                        sx={{ marginLeft: 1, textTransform: 'none' }}
                    >
                        Cambiar Contraseña
                    </Button>
                </Box>
            ),
        },
    ];

    const calculateGridHeight = () => {
        const rowHeight = 52; // Altura de cada fila
        const headerHeight = 56; // Altura del header
        const paginationHeight = 56; // Altura de la paginación
        const totalHeight = headerHeight + (users.length * rowHeight) + paginationHeight;
        return totalHeight < 400 ? totalHeight : 400; // Altura máxima de 400
    };

    return (
        <ThemeProvider theme={theme}>
            <Container>
                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', mt: 4 }}>
                    {error && (
                        <Alert severity="error" sx={{ mb: 2, width: '100%' }}>
                            {error}
                        </Alert>
                    )}
                    {success && (
                        <Alert severity="success" sx={{ mb: 2, width: '100%' }}>
                            {success}
                        </Alert>
                    )}
                    <Box component="form" onSubmit={handleRegister} sx={{ width: '100%', maxWidth: 800 }}>
                        <Typography variant="h6" align="left">Registro de Usuarios</Typography>
                        <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '5%' }}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    id="email"
                                    label="Correo Electrónico"
                                    name="email"
                                    autoComplete="email"
                                    size="small"
                                    autoFocus
                                    value={userData.email}
                                    onChange={(e) => setUserData(data => ({ ...data, email: e.target.value }))}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="password"
                                    label="Contraseña"
                                    type={showPassword ? 'text' : 'password'}
                                    size="small"
                                    id="password"
                                    autoComplete="current-password"
                                    value={userData.password}
                                    onChange={handlePasswordChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                                <IconButton
                                                    aria-label="generate password"
                                                    onClick={handleGeneratePassword}
                                                    edge="end"
                                                >
                                                    <Refresh />
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                                <Box sx={{ mt: 1 }}>
                                    <Typography variant="body2" style={{ color: getPasswordStrengthColor(passwordStrength) }}>
                                        Fuerza de la contraseña: {['Muy Débil', 'Débil', 'Regular', 'Buena', 'Muy Buena'][passwordStrength]}
                                    </Typography>
                                </Box>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    variant="outlined"
                                    required
                                    fullWidth
                                    name="confirmPassword"
                                    label="Confirmar Contraseña"
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    size="small"
                                    id="confirmPassword"
                                    value={userData.confirmPassword}
                                    onChange={(e) => setUserData(data => ({ ...data, confirmPassword: e.target.value }))}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle confirm password visibility"
                                                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                                                    onMouseDown={(e) => e.preventDefault()}
                                                    edge="end"
                                                >
                                                    {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel id="role-label">Privilegio</InputLabel>
                                    <Select
                                        labelId="role-label"
                                        id="role"
                                        value={userData.admin}
                                        onChange={(e) => setUserData(data => ({ ...data, admin: e.target.value }))}
                                        label="Privilegio"
                                        required
                                    >
                                        <MenuItem value={1}>Administrador</MenuItem>
                                        <MenuItem value={0}>Usuario</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    color="primary"
                                    sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                                >
                                    Registrar Nuevo Usuario
                                </Button>
                            </Grid>
                        </Grid>
                    </Box>
                </Box>
                <Box sx={{ mt: 4 }}>
                    <Typography variant="h6" align="left">Usuarios Registrados</Typography>
                    <div style={{ height: calculateGridHeight(), width: '100%', marginTop: '3%'}}>
                        <DataGrid
                            rows={users}
                            columns={columns}
                            pageSize={5}
                            loading={loading}
                            rowsPerPageOptions={[5, 10, 20]}
                            autoHeight
                        />
                    </div>
                </Box>

                <Dialog
                    open={openChangePassword} onClose={handleCloseChangePassword}>
                    <DialogTitle>Cambiar Contraseña</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Por favor, ingrese su contraseña actual y la nueva contraseña dos veces para confirmarla.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="old_password"
                            label="Contraseña Actual"
                            type={showOldPassword ? 'text' : 'password'}
                            fullWidth
                            value={passwordData.old_password}
                            onChange={(e) => setPasswordData(data => ({ ...data, old_password: e.target.value }))}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle old password visibility"
                                            onClick={() => setShowOldPassword(!showOldPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showOldPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <TextField
                            margin="dense"
                            id="new_password"
                            label="Nueva Contraseña"
                            type={showNewPassword ? 'text' : 'password'}
                            fullWidth
                            value={passwordData.new_password}
                            onChange={handleNewPasswordChange}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle new password visibility"
                                            onClick={() => setShowNewPassword(!showNewPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                        <IconButton
                                            aria-label="generate new password"
                                            onClick={handleGenerateNewPassword}
                                            edge="end"
                                        >
                                            <Refresh />
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                        <Box sx={{ mt: 1 }}>
                            <Typography variant="body2" style={{ color: getPasswordStrengthColor(passwordStrengthChange) }}>
                                Fuerza de la nueva contraseña: {['Muy Débil', 'Débil', 'Regular', 'Buena', 'Muy Buena'][passwordStrengthChange]}
                            </Typography>
                        </Box>
                        <TextField
                            margin="dense"
                            id="confirm_password"
                            label="Confirmar Nueva Contraseña"
                            type={showConfirmNewPassword ? 'text' : 'password'}
                            fullWidth
                            value={passwordData.confirm_password}
                            onChange={(e) => setPasswordData(data => ({ ...data, confirm_password: e.target.value }))}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle confirm new password visibility"
                                            onClick={() => setShowConfirmNewPassword(!showConfirmNewPassword)}
                                            onMouseDown={(e) => e.preventDefault()}
                                            edge="end"
                                        >
                                            {showConfirmNewPassword ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                )
                            }}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant='outlined'
                            onClick={handleCloseChangePassword} color="error">
                            Cancelar
                        </Button>
                        <Button
                            variant='outlined'
                            onClick={handleChangePassword} color="primary">
                            Cambiar Contraseña
                        </Button>
                    </DialogActions>
                </Dialog>
            </Container>
        </ThemeProvider>
    );
};

export default UserRegister;
