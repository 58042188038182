import axios from '../../../utils/axiosConfig';
import apiUrl from "../../config_url";

export default async function connectPool(dataToSend){
    try {
        const response = await axios.post(`${apiUrl}/sql_server/connect_pool`, dataToSend);
        if(response.status === 200) return response.data
        return {error: response.data.error || "Ha habido un error"}
    } catch (error) {
        console.error(error);
        return {error:error?.response?.data?.error || "Ha habido un error al cargar los clientes"}
    }  
}