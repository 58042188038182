import axios from '../../../utils/axiosConfig';
import apiUrl from "../../config_url";

export default async function getDataPool(customerId){
    try {
        const response = await axios.post(`${apiUrl}/sql_server/get_data_pool`, {user_id:customerId});
        if(response.status === 200) return response.data
        return {error: response.data.error || "Ha habido un error"}
    } catch (error) {
        console.error(error);
        return {error:error?.response?.data?.error || "Ha habido un error al cargar los clientes"}
    }  
}